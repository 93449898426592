import { navigate } from "gatsby"
import qs from "qs"
import React, { useEffect, useState } from "react"
import { Flex, Text } from "rebass"

import Popup from "../../components/popup"
import LoginButtons from "../../components/login-buttons"
import LoginLayout from "../../components/login-layout"
import Spinner from "../../components/spinner"
import { useAuth } from "../../context/auth"
import MedusaClient from "../../services/api"

const GITHUB_CLIENT_ID = process.env.GATSBY_GITHUB_CLIENT_ID || ""
const GITHUB_REDIRECT_URI = process.env.GATSBY_GITHUB_REDIRECT_URI || ""

const IndexPage = () => {
  const auth = useAuth()
  const [loading, setLoading] = useState(false)

  const handleGithub = async () => {
    setLoading(true)
    const { data } = await MedusaClient.auth.createCsrfToken({
      provider: "github"
    })

    const params = {
      client_id: GITHUB_CLIENT_ID,
      redirect_uri: GITHUB_REDIRECT_URI,
      scope: "user",
      state: data.csrf
    }
    const qstring = Object.keys(params)
      .map(k => `${k}=${params[k]}`)
      .join("&")
    const loginUrl = `https://github.com/login/oauth/authorize?${qstring}`

    const popup = Popup.open("github-identity", loginUrl, window, {
      height: 1000,
      width: 600
    })

    popup.then(
      res => {
        auth
          .handleLogin("github", {
            state: res.state,
            code: res.code
          })
          .then(data => {
            if (data.has_account) {
              navigate("/a")
            } else {
              navigate(
                `/signup/github?${qs.stringify({
                  ...data.details,
                  state: res.state
                })}`
              )
            }
          })
      },
      () => {}
    )
  }

  const responseGoogle = response => {
    if (response.getAuthResponse) {
      setLoading(true)
      const res = response.getAuthResponse()
      const token = res.id_token
      auth
        .handleLogin("google", {
          token
        })
        .then(data => {
          if (data.has_account) {
            navigate("/a")
          } else {
            navigate(
              `/signup/google?${qs.stringify({ ...data.details, token })}`
            )
          }
        })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (auth.isLoggedIn) {
      setLoading(true)
      navigate("/a")
    }
  }, [])

  return (
    <LoginLayout pt={3} title={"Login"}>
      <Text mb={4} fontWeight="bold" fontSize={4}>
        Sign in to your account
      </Text>
      {loading ? (
        <Flex justifyContent="center">
          <Spinner dark width="20px" height="20px" />
        </Flex>
      ) : (
        <LoginButtons
          onGithub={handleGithub}
          onGoogleSucess={responseGoogle}
          onGoogleFailure={responseGoogle}
          setLoading={setLoading}
          onEmail={() => navigate("/login/email")}
        />
      )}
      <Text fontSize={0} mt={1}>
        Don't have an account? Click an option above to sign up
      </Text>
    </LoginLayout>
  )
}

export default IndexPage
